export const getToken = () => {
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    const token = cookies.filter((c) => c.indexOf('accessToken') > -1);
    if (token && token.length > 0) {
      return token[0].substring(token[0].indexOf('=') + 1);
    }
  }
  return;
};

export const deleteToken = () => {
  if (document.cookie) {
    document.cookie = 'accessToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC';
  }
};

export const isMobile = () => {
  return window && window.innerWidth <= 640;
};

export const isTablet = () => {
  return window && window.innerWidth < 1280;
};

export const isDesktop = () => {
  return window && window.innerWidth > 1280;
};

export const formatTerm = (term) => {
  if (term === '1' || term === 'MTM') {
    return 'Month-to-Month';
  }

  return `${term} months`;
};

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `${match[1] ? `(${match[1]})` : ''}${match[2] ? ' ' : ''}${match[2]}${
      match[3] ? '-' : ''
    }${match[3]}`;
  }
  return cleaned;
}

export const getCookieColorByBrand = () => {
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    const color = cookies.filter((c) => c.indexOf('colorBrand') > -1);
    if (color && color.length > 0) {
      return color[0].substring(color[0].indexOf('=') + 1);
    }
  }
  return;
};

export const getCookieHasMultipleLeases = () => {
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    const hasMultipleLeases = cookies.filter((c) => c.indexOf('hasMultipleLeases') > -1);
    if (hasMultipleLeases?.length) {
      return hasMultipleLeases[0].substring(hasMultipleLeases[0].indexOf('=') + 1);
    }
  }
  return;
};

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const formatCurrency = (amount) => {
  return (
    amount &&
    amount.toLocaleString('en-US', {
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
};
