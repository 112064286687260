import React, { useContext } from 'react';
import Header from '../../../shared/Header';

import { UIContext } from '../../../context/UIContext';
import { useNavigate } from 'react-router-dom';

import iconError from '../../../assets/img/close-circle.png';
import checkCircle from '../../../assets/img/refresh-icon.png';

const ErrorScreen = () => {
  const { dashboardData, refresh } = useContext(UIContext);
  const navigate = useNavigate();

  const handleRefresh = () => {
    refresh();
    navigate('/');
  };

  return (
    <>
      {dashboardData?.resident ? <Header resident={dashboardData.resident} /> : <Header />}

      <div className="grid max-h-screen place-content-center body">
        <div className="flex w-full justify-center pt-[14%]  items-center content-center">
          <img src={iconError} className="w-[70px] md:w-[100px]" alt="icon_error" />
        </div>
        <div className="flex w-full justify-center pt-[5%] items-center content-center">
          <p className="text-[#31343A] text-[50px] md:text-[80px] font-proxima font-bold">Error!</p>
        </div>
        <div className="pt-[10%]">
          <div className="flex items-center justify-center ">
            <div className="w-[80%] md:w-full">
              <p className="text-[24px] text-center text-[#31343A] font-proxima font-semibold">
                Sorry, something went wrong on our end. We are working on fixing the problem. <br />
                Please try again.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center  pt-[8%] md:pt-[5%]">
            <div className="pr-4" onClick={handleRefresh}>
              <img
                src={checkCircle}
                className="w-[30px] cursor-pointer md:w-[50px]"
                alt="icon_circle"
              />
            </div>
            <p
              onClick={handleRefresh}
              className="text-[#06A7D8] cursor-pointer text-[20px] md:text-[24px] font-proxima underline font-semibold">
              Refresh Page
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorScreen;
