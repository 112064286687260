import { Routes, Route } from 'react-router-dom';

import ScrollToTop from './utils/ScrollToTop';

import FormContainer from './pages/form/FormContainer';
import { ViewportProvider } from './utils/useViewport';
import { LoaderProvider } from './utils/useLoader';
import ErrorContainer from './pages/exceptions/ErrorContainer';
import UIProvider from './context/UIProvider';
import SelectLeaseContainer from './pages/multipleLeases/SelectLeaseContainer';
import MaintenanceContainer from './pages/exceptions/MaintenanceContainer';

function App() {
  return (
    <UIProvider>
      <ViewportProvider>
        <LoaderProvider>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<SelectLeaseContainer />} />
              <Route path="/form" element={<FormContainer />} />
              <Route exact path="/error/:errorType" element={<ErrorContainer />} />
              <Route exact path="/maintenance" element={<MaintenanceContainer />} />
            </Routes>
          </ScrollToTop>
        </LoaderProvider>
      </ViewportProvider>
    </UIProvider>
  );
}

export default App;
