import { useParams } from 'react-router-dom';

import ErrorScreen from './components/ErrorScreen';
import UnauthorizedScreen from './components/UnauthorizedScreen';

const ErrorContainer = () => {
  let { errorType } = useParams();
  return <>{errorType === 'unauthorized' ? <UnauthorizedScreen /> : <ErrorScreen />}</>;
};

export default ErrorContainer;
