import { useContext } from 'react';
import { UIContext } from '../../../../../context/UIContext';

const MainInfoBuilding = () => {
  const { formData } = useContext(UIContext);
  const { community, lease } = formData;

  return (
    <div className="bg-white  rounded w-11/12 m-3 lg:w-11/12 lg:m-10  md:m-6">
      <div className="font-proxima m-5 ">
        <div
          aria-label="community-name"
          className="flex content-center  justify-items-center justify-center items-center">
          <p className=" text-xs lg:text-base lg:font-bold md:text-base md:font-bold   ">
            {community?.name}
          </p>
        </div>
        <div
          aria-label="community-info"
          className="flex text-center  justify-items-center justify-center items-center">
          <p className="text-xs lg:text-subHeader md:text-subHeader  text-[#525761] ">
            {community?.address.addressLine1},&nbsp;{community?.address.city},&nbsp;
            {community?.address.state},&nbsp;{community?.address.zip}
          </p>
        </div>
        <div
          aria-label="unit-info"
          className="flex content-center  justify-items-center justify-center items-center">
          <p className="text-xs lg:text-subHeader  md:text-subHeader  font-light text-[#31343A]  flex content-center  justify-items-center justify-center items-center">
            {lease?.leaseId}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainInfoBuilding;
