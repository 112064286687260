import ProgressLine from './ProgressLine';
import loader_bg from '../assets/img/loader_bg.mp4';

const Loading = () => {
  return (
    <div>
      <div className="flex-col justify-center items-center h-screen">
        <div className="flex justify-center">
          <div className="xs:pl-0  md:pl-[2rem]">
            <video autoPlay muted loop width="950" height="950">
              <source src={loader_bg} type="video/mp4"></source>
              Loading..
            </video>
          </div>
        </div>
        <ProgressLine />
      </div>
    </div>
  );
};

export default Loading;
