import React from 'react';
import icon_error from '../assets/img/icon_error.png';

export const ErrorMessage = () => {
  return (
    <div className="left-0 absolute inset-x-0 top-[10%] md:top-[10%] w-full flex items-center justify-center content-center">
      <div
        className="p-4 mb-4 w-[300px] md:w-[550px] lg:w-[750px] max-h-[40px] flex items-center font-proxima text-tiny  md:text-sm text-[#31343A] bg-[#FFF1F0] rounded-[4px] dark:bg-red-200 dark:text-red-800 border border-[#FFA39E]"
        role="alert">
        <span className="font-medium flex">
          <div className="flex items-center justify-center content-center">
            <img src={icon_error} className="w-[1rem] mr-2 float-left" alt="error" />
          </div>
          Sorry! An unexpected error has occurred. Please try again.
        </span>
      </div>
    </div>
  );
};
