/* eslint-disable no-unused-vars */
import { useReducer } from 'react';
import { UIContext } from './UIContext';
import uiReducer from './UIReducer';
import { useNavigate } from 'react-router-dom';
import { deleteToken, eraseCookie, getCookieColorByBrand, getToken } from '../utils/commonMethods';
import { noticeToVacateAPI } from '../api/noticeToVacateAPI';

const UI_INITIAL_STATE = {
  disable: false,
  loading: true,
  formData: [],
  messageDialogOpen: false,
  isSubmitFormSuccess: false,
  showSuccessMessage: false,
  showConfirmationPaymentMessage: false,
  showErrorMessage: false,
  submitingFormNTV: false,
  form: [],
  hasMultipleLeases: false,
  hasSelectedLease: false,
  error: '',
  authLeases: []
};

// METHODS
const METHOD_FORM = 'vacate/form';
const METHOD_LOGIN = 'vacate/login';

const UIProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useReducer(uiReducer, UI_INITIAL_STATE);
  const navigate = useNavigate();

  const deleleteEmptyObjects = (form) => {
    for (let value in form) {
      if (typeof form[value] == 'object' && JSON.stringify(form[value]) === '{}') {
        delete form[value];
      }
    }
    return form;
  };

  const errorHandler = (error) => {
    dispatch({ type: 'errorFetchData', payload: error });

    if (error?.response?.status === 401) {
      return navigate('../error/unauthorized', { replace: true });
    }
    if (error?.response?.status === 503) {
      return navigate('../maintenance', { replace: true });
    } else {
      return navigate('../error/generic', { replace: true });
    }
  };

  const getFormData = async () => {
    try {
      dispatch({ type: 'waitingResponse' });
      const response = await noticeToVacateAPI.get(METHOD_FORM);

      if (response.status === 200) {
        const { data } = response;

        dispatch({ type: 'getFormData', payload: data });

        return data;
      } else {
        return errorHandler({ response: { status: response.status } });
      }
    } catch (error) {
      if (!getToken()) {
        return errorHandler({ response: { status: 401 } });
      }
      dispatch({ type: 'errorFetchData' });
      errorHandler(error);
    }
  };

  const openMessageDialog = () => {
    dispatch({ type: 'openMessageDialog' });
  };

  const closeMessageDialog = () => {
    dispatch({ type: 'closeMessageDialog' });
  };

  const openPaymentDialog = () => {
    dispatch({ type: 'openPaymentDialog' });
  };

  const setFormNoticeToVacate = (form) => {
    dispatch({ type: 'setFormNoticeToVacate', payload: form });
  };

  const refresh = () => {
    dispatch({ type: 'refresh' });
  };

  const handleErrorSubmitingForm = () => {
    dispatch({ type: 'openDialogError' });
    dispatch({ type: 'submitedForm' });
    dispatch({ type: 'errorFetchData' });
    setTimeout(() => {
      dispatch({ type: 'closeDialogError' });
    }, 6000);
  };

  const getColorByBrand = (brand) => {
    const colorBrand = getCookieColorByBrand();
    const COLORS_BRAND = {
      ava: '#E40087',
      avalon: '#c64d5b',
      eaves: '#51832A',
      kanso: '#243746'
    };

    const DEFAULT_COLOR = colorBrand ? COLORS_BRAND[colorBrand] : '#202F59';
    const color = COLORS_BRAND[brand] || DEFAULT_COLOR;
    return color;
  };

  const handleSubmitForm = async (form) => {
    const data = deleleteEmptyObjects(form);

    dispatch({ type: 'submitingForm' });
    try {
      const response = await noticeToVacateAPI.post(METHOD_FORM, data);

      if (response.status === 204 || response.status === 409) {
        dispatch({ type: 'handleSubmitForm' });
        dispatch({ type: 'openMessageDialog' });
        dispatch({ type: 'submitedForm' });
      } else {
        handleErrorSubmitingForm();
      }
    } catch (error) {
      handleErrorSubmitingForm();
    }
  };

  const logout = async (event) => {
    event.preventDefault();
    deleteToken();
    eraseCookie('colorBrand');
    // eslint-disable-next-line no-undef
    window.location.href = process.env.REACT_APP_AVALON_ACCESS_LOGOUT_URL;
  };

  const getAuth = async () => {
    dispatch({ type: 'waitingResponse' });
    try {
      if (!getToken()) {
        localStorage.setItem('hasMultipleLeases', false);
        return errorHandler({ response: { status: 401 } });
      }
      const response = await noticeToVacateAPI.get(METHOD_LOGIN);
      if (response.status === 200) {
        const { data } = response;
        const hasMultipleLeases = data.leases.length > 1;

        localStorage.setItem('hasMultipleLeases', hasMultipleLeases);

        const authPayload = {
          authLeases: hasMultipleLeases ? data : [],
          hasMultipleLeases
        };

        if (!hasMultipleLeases) {
          return navigate('../form', { replace: true });
        }
        dispatch({ type: 'getAuth', payload: authPayload });
      }
    } catch (error) {
      errorHandler(error);
      localStorage.setItem('hasMultipleLeases', false);
    }
  };

  const postAuth = async (leaseId) => {
    try {
      const response = await noticeToVacateAPI.post(METHOD_LOGIN, { leaseId });
      if (response.status === 200) {
        dispatch({ type: 'selectLease', payload: { hasSelectedLease: true } });
        dispatch({ type: 'postAuth' });
        return navigate('../form', { replace: true });
      }
    } catch (error) {
      dispatch({ type: 'errorFetchData' });
      throw error;
    }
  };

  const updateValidationForm = ({
    isCurrentFormValid,
    moveOutInformationRes,
    reasonToMoveOutRes,
    forwardingAddressRes,
    termsRes
  }) => {
    try {
      const noticeToVacatePayload = {
        isCurrentFormValid,
        moveOutInformationRes,
        reasonToMoveOutRes,
        forwardingAddressRes,
        termsRes
      };
      dispatch({ type: 'updateValidationForm', payload: noticeToVacatePayload });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UIContext.Provider
      value={{
        ...state,
        getFormData,
        openMessageDialog,
        closeMessageDialog,
        openPaymentDialog,
        handleSubmitForm,
        setFormNoticeToVacate,
        updateValidationForm,
        getColorByBrand,
        refresh,
        getAuth,
        postAuth,
        logout
      }}>
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
