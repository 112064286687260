/* eslint-disable no-undef */
import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { UIContext } from '../context/UIContext';
import SpinnerIcon from './SpinnerIcon';
import { ErrorMessage } from './ErrorMessage';

const DialogMessage = ({
  open,
  title,
  body,
  icon,
  confirmButton = '',
  cancelButton = '',
  isPaymentDialog = false,
  goHome = false
}) => {
  const {
    closeMessageDialog,

    handleSubmitForm,
    showConfirmationPaymentMessage,
    form,
    submitingFormNTV,
    showErrorMessage,
    getColorByBrand,
    formData
  } = useContext(UIContext);

  const { community } = formData;
  const handleConfirm = () => {
    handleSubmitForm(form);
  };

  const confirmationFlow = (isPaymentDialog) => {
    if (isPaymentDialog) {
      closeMessageDialog();
      return (window.location.href = `${process.env.REACT_APP_AVALON_ACCESS_PAYMENT_URL}`);
    } else {
      handleConfirm();
    }
  };

  const handleClose = (cancelButton) => {
    if (showConfirmationPaymentMessage && cancelButton.toUpperCase() !== 'CANCEL') {
      window.location.href = `${process.env.REACT_APP_RENEWALS_URL}/dashboard`;
    } else {
      closeMessageDialog();
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          data-theme={community?.brand}
          className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center"
          onClose={closeMessageDialog}
          open>
          <Dialog.Overlay
            className={
              'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-none'
            }></Dialog.Overlay>
          <div
            className={`box-border w-[340px]  ${showConfirmationPaymentMessage ? 'lg:w-[550px] xs:w-[340px] md:w-[550px]' : 'lg:w-[447px]'}  md:w-[407px] bg-white shadow-lg   rounded-lg transform transition-all absolute border-boxborder font-proxima h-auto text-center`}>
            <div>
              {goHome ? (
                <a href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}>
                  <button
                    className="float-right outline-0 text-black pt-5 pr-3"
                    onClick={closeMessageDialog}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 font-extralight text-[#979AA0] "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </a>
              ) : (
                <button
                  className="float-right outline-0 text-black  md:pt-5 md:pr-5 pt-5 pr-3"
                  onClick={closeMessageDialog}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 font-extralight text-[#979AA0] "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
              <div className={`flex items-center pl-5 pr-5 pb-1 pt-5`}>
                <img
                  src={icon}
                  className="xs:w-[1rem]  md:w-[1.5rem] mr-2 float-left pb-3"
                  alt="error"
                />
                <h2 className="align-left text-xs md:text-base font-bold text-left pb-3">
                  {title}
                </h2>
              </div>

              <div className={`${showConfirmationPaymentMessage && 'border-t  mb-5'}`}></div>
              <div
                className={`text-left  text-tiny md:text-sm text-[#31343A] font-normal mt-5 pl-6 pr-6 pt-0 pb-0  `}>
                {body}
              </div>
              <span className="flex w-[100%] items-center justify-center pb-6">
                {cancelButton && (
                  <button
                    className={`  font-semibold text-center ${showConfirmationPaymentMessage ? ' xs:text-tiny md:text-[14px] xs:w-[200px] xs:h-[38px] md:h-[32px]  md:w-[200px] p-0 mx-2 bg-[#54575E] text-white ' : 'xs:w-[70px] md:w-[84px]  xs:text-tiny md:text-[14px] p-3 mx-5 hover:border-primary hover:text-primary'}  flex items-center justify-center rounded mt-5 h-[32px] border border-input-border `}
                    onClick={() => handleClose(cancelButton)}>
                    {cancelButton}
                  </button>
                )}
                {goHome && (
                  <a href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}>
                    <button
                      className="mx-5 btn-bg text-white text-center flex  items-center justify-center w-[182px] p-3 rounded mt-5 h-[32px]"
                      onClick={closeMessageDialog}>
                      {confirmButton}
                    </button>
                  </a>
                )}
                {!goHome && submitingFormNTV && (
                  <button
                    className={`mx-5 btn-bg text-white text-center flex disabled:bg-[#31343a]/25  items-center justify-center ${showConfirmationPaymentMessage ? 'xs:text-tiny font-bold  md:text-[14px]  xs:h-[38px] md:h-[32px]  md:w-[270px] mx-2 ' : ' xs:text-tiny md:w-[182px] mx-5'} p-3 rounded mt-5 h-[32px]`}
                    disabled>
                    <div className="w-[100px] h-[110px] flex justify-center items-center">
                      <SpinnerIcon colorFill={getColorByBrand(formData.community?.brand)} />
                    </div>
                  </button>
                )}
                {!submitingFormNTV && !goHome && (
                  <button
                    className={` text-white btn-bg text-center flex  items-center justify-center ${showConfirmationPaymentMessage ? 'xs:text-tiny  font-bold md:text-[14px] xs:h-[38px] md:h-[32px]  md:w-[270px] mx-2 ' : ' xs:text-tiny md:w-[182px] md:text-[16px] mx-5'} h-[32px] p-3 rounded mt-5 `}
                    onClick={() => confirmationFlow(isPaymentDialog)}>
                    {confirmButton}
                  </button>
                )}
              </span>
            </div>
          </div>
          {showErrorMessage && <ErrorMessage />}
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default DialogMessage;
