import React, { useContext } from 'react';
import { UIContext } from '../../../../../context/UIContext';
import { formatPhoneNumber } from '../../../../../utils/commonMethods';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

const ResidentItem = ({ responsableResident, counter, idx }) => {
  const { formData } = useContext(UIContext);
  const styles = { fontTextColumn: 'font-bold text-[17px] lg:text-sm md:text-sm' };

  return (
    <>
      {responsableResident[idx] && Object.keys(responsableResident[idx]).length !== 0 ? (
        <div className="grid lg:gap-4 md:w-[45%]">
          <p
            className={`${styles.fontTextColumn}  ${
              !responsableResident[idx]?.email && 'text-[#F5222D]'
            }  `}>
            Resident {counter}&nbsp;{' '}
            {isEqual(responsableResident[idx], formData?.resident) && (
              <span className="text-sm text-[#06A7D8]"> &nbsp;(You)</span>
            )}
          </p>
          <div aria-label="resident-info" className="text-xs lg:text-sm md:text-sm overflow-hidden">
            <p>
              {responsableResident[idx]?.firstName} {responsableResident[idx]?.lastName}
            </p>
            <p>
              {responsableResident[idx]?.phoneNumber &&
                formatPhoneNumber(responsableResident[idx]?.phoneNumber)}
            </p>
            {responsableResident[idx]?.email ? (
              <p className="break-words">{responsableResident[idx]?.email}</p>
            ) : (
              <p aria-label="email-required" className="text-[#F5222D]">
                Email Required!
              </p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

ResidentItem.propTypes = {
  counter: PropTypes.number,
  idx: PropTypes.number,
  responsableResident: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      guarantor: PropTypes.string
    })
  )
};

export default ResidentItem;
