/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import AccessMenu from './AccessMenu';
import SignOutMenu from './SignOutMenu';

const Header = () => {
  return (
    <header className="border-b-[3px] bg-white">
      <div className="container max-w-screen-xl lg:w-[680px] xl:w-full mx-auto">
        <div className="flex items-center justify-between my-1 p-8 md:py-1 md:px-10 lg:px-0 xl:px-2 sm:px-5">
          <SignOutMenu />
          <div className="text-[16px] text-black font-semibold capitalize font-proxima">
            <h1 className="text-[#31343A] md:text-[16px] lg:text-[24px]">Lease Manager</h1>
          </div>
          <AccessMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
