/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { UIContext } from '../../../../../context/UIContext';

import TableResidents from './TableResidents';
import StepButtons from '../../../../../shared/StepButtons';

const OccupantDetails = ({ coresidents, handleStep, handleBack, setIsValidStep }) => {
  const { formData } = useContext(UIContext);
  const [residentsAndCoresidents, setResidentsAndCoresidents] = useState([]);
  const { occupants, resident, guarantors } = formData;

  const createMatrixOfResidents = () => {
    let array = [];
    let counter = 1;
    let residentsAndCoresidents = [resident, ...coresidents];
    for (let i = 0; i < residentsAndCoresidents.length; i = i + 2) {
      array.push([residentsAndCoresidents[i], residentsAndCoresidents[counter]]);
      counter = counter + 2;
    }
    return setResidentsAndCoresidents(array);
  };

  useEffect(() => {
    createMatrixOfResidents();
    setIsValidStep(true);
  }, []);

  return (
    <div className="bg-white  rounded w-11/12 lg:m-10 m-3 md:m-6">
      <div className=" font-proxima">
        <div className="mb-5">
          <p className=" text-sm font-semibold  md:text-[24px] md:font-medium">
            Occupant(s) Details
          </p>
          <p className="text-tiny lg:text-sm md:text-sm md:pt-2">
            If an email address is incorrect, please inform that resident to update their email
            address within AvalonAccess or reach out to the community for help prior to submitting
            this form.
          </p>
        </div>
        <div className="lg:flex  md:flex  mb-5">
          <div className="md:flex-1 flex-column">
            {residentsAndCoresidents.map((responsableResident, idx) => (
              <TableResidents key={idx} responsableResident={responsableResident} idx={idx} />
            ))}
          </div>
        </div>
        <div className="lg:flex  md:flex  mb-5  pt-5 border-t">
          <div className="grid lg:gap-4 md:w-[45%]">
            <div>
              <p className="font-bold text-[17px] lg:text-sm md:text-sm  false">
                Other Occupant(s)
              </p>
            </div>
            <div className="text-sm" aria-label="occupant-info">
              {occupants?.length > 0 &&
                occupants?.map((otherOccupant, idx) => (
                  <p key={idx}>
                    {otherOccupant?.firstName}&nbsp;{otherOccupant?.lastName}
                  </p>
                ))}
            </div>
          </div>
          <div className="md:w-2/12"></div>
          <div className="grid lg:gap-4 md:w-[45%]">
            <div>
              <p className="font-bold text-[17px] lg:text-sm md:text-sm  false">Guarantor(s)</p>
            </div>
            <div
              className="text-xs lg:text-sm md:text-sm overflow-hidden"
              aria-label="occupant-info">
              {guarantors?.length > 0 &&
                guarantors?.map((guarantor, idx) => (
                  <div>
                    <p key={idx}>
                      {idx + 1}.&nbsp;{guarantor?.firstName}&nbsp;{guarantor?.lastName}&nbsp;
                    </p>
                    {guarantor?.email && <p key={idx}>{guarantor.email}</p>}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="xs:invisible md:visible">
        <StepButtons isValidForm={true} handleBack={handleBack} handleStep={handleStep} />
      </div>
    </div>
  );
};

export default OccupantDetails;
