import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '14px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    boxShadow: theme.shadows[1]
  }
}));

const ToolTipInfo = ({ icon, text }) => {
  return (
    <LightTooltip title={text} placement="top">
      <img src={icon} width={12} alt="icon" />
    </LightTooltip>
  );
};

export default ToolTipInfo;
