import { useState, useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { UIContext } from '../../../../../context/UIContext';
import DropDown from '../../../../../shared/DropDown';
import StepButtons from '../../../../../shared/StepButtons';

const ForwardingAddress = ({
  register,
  errors,
  clearErrors,
  resetField,
  disableForm,
  watch,
  setValue,
  dirtyFields,
  control,
  handleStep,
  handleBack,
  setIsValidStep
}) => {
  const [disable, setDisable] = useState(false);
  const [minLengthZip, setMinLengthZip] = useState(5);
  const [countryItem, setCountryItem] = useState({});
  const [orderCountries, setOrderCountries] = useState([]);
  const [forwardingAddressForm, setForwardingAddressForm] = useState({});

  const {
    getColorByBrand,
    formData,
    moveOutInformationRes,
    reasonToMoveOutRes,
    forwardingAddressRes,
    updateValidationForm
  } = useContext(UIContext);
  const { community, countries } = formData;
  const brandColor = getColorByBrand(community?.brand);
  const watchZip = watch('zip');

  const handleChange = (e) => {
    const propertiesToReset = ['street', 'addressLine2', 'city', 'state', 'zip', 'country'];
    if (e.target.checked) {
      clearErrors(propertiesToReset);
      propertiesToReset.map((prop) => resetField(prop));
    }
    setValue('isForwardingAddressUnknown', e.target.checked);
    setDisable(e.target.checked);
  };

  const orderAlphCountries = (countries) => {
    const sortedCountries = countries?.sort((a, b) => a.countryName.localeCompare(b.countryName));
    if (!forwardingAddressRes?.countrySelected) {
      setCountryItem(countries?.filter(({ countryCode }) => countryCode === 'US')[0]);
    }
    return setOrderCountries(sortedCountries);
  };

  const zipValidation = (zip) => {
    zip?.toUpperCase() === 'N/A' ? setMinLengthZip(3) : setMinLengthZip(5);
  };

  const contrySelection = (country) => {
    setCountryItem(country);
  };

  const getRequiredMessage = () => {
    if (disable) return false;
    if (!Object.keys(countryItem).length || countryItem.countryCode === 'US') {
      return 'Please enter State';
    }
    return false;
  };

  const updateForm = (data) => {
    const { zip, street, city, state, addressLine2, isForwardingAddressUnknown, countrySelected } =
      data;

    setForwardingAddressForm({
      zip,
      street,
      city,
      state,
      addressLine2,
      isForwardingAddressUnknown,
      countrySelected
    });

    updateValidationForm({
      moveOutInformationRes,
      reasonToMoveOutRes,
      forwardingAddressRes: {
        zip,
        street,
        city,
        state,
        addressLine2,
        isForwardingAddressUnknown,
        countrySelected
      }
    });
  };

  const validateBtn = () => {
    if (forwardingAddressForm?.isForwardingAddressUnknown) {
      setIsValidStep(true);
      return true;
    }

    if (Object.keys(errors).length > 0) {
      setIsValidStep(false);
      return false;
    }

    const isCountryUS = forwardingAddressForm?.countrySelected?.countryCode === 'US';

    const requiredFields = isCountryUS
      ? ['street', 'city', 'state', 'zip']
      : ['street', 'city', 'zip'];

    const missingFields = requiredFields.filter((field) => {
      const value = forwardingAddressForm[field];
      return value === undefined || value === '';
    });

    const validFields = Object.keys(forwardingAddressForm)?.length && missingFields.length === 0;
    setIsValidStep(validFields);
    return validFields;
  };

  useEffect(() => {
    if (countryItem !== undefined) {
      const propertiesToReset = ['street', 'addressLine2', 'city', 'state', 'zip', 'country'];
      clearErrors(propertiesToReset);
      propertiesToReset.forEach((prop) => resetField(prop));
    }
  }, [countryItem]);

  useEffect(() => {
    const subscription = watch((data) => {
      zipValidation(data['zip']);
      contrySelection(data['countrySelected']);

      const updatedData = {
        ...moveOutInformationRes,
        ...forwardingAddressRes,
        ...Object.keys(data).reduce((acc, key) => {
          if (data[key] !== undefined) {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      };
      updateForm(updatedData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, forwardingAddressRes]);

  useEffect(() => {
    countries?.length && orderAlphCountries(countries);
  }, [countries]);

  const prevState = () => {
    setForwardingAddressForm(forwardingAddressRes);
    if (forwardingAddressRes.isForwardingAddressUnknown) {
      const propertiesToReset = ['street', 'addressLine2', 'city', 'state', 'zip', 'country'];

      clearErrors(propertiesToReset);
      propertiesToReset.map((prop) => resetField(prop));

      setValue('isForwardingAddressUnknown', true);
      setDisable(true);
    }
  };

  useEffect(() => {
    forwardingAddressRes && prevState();
  }, []);

  return (
    <div className="bg-white rounded w-11/12 lg:m-10 m-3 md:m-6 ">
      <div className=" font-proxima">
        <p className="font-proxima  text-sm font-semibold mb-1 md:text-[24px] md:font-medium">
          Forwarding Address
        </p>
        <div className="text-tiny md:text-sm mb-2">
          <p>
            To ensure the security deposit is delivered in a timely manner, please provide a
            complete forwarding address. If State, Zip Code is not applicable for the country to
            which you are moving, please enter “N/A”. <br></br>
            <br></br>As a reminder, we will send the security deposit information and any refund to
            all residents at this listed forwarding address.
          </p>
        </div>
        <div className="flex items-center  md:mb-5 md:mt-5">
          <input
            type="checkbox"
            aria-label="isForwardingAddressUnknown"
            disabled={disableForm}
            checked={forwardingAddressForm?.isForwardingAddressUnknown || false}
            id="isForwardingAddressUnknown"
            name="isForwardingAddressUnknown"
            {...register('isForwardingAddressUnknown')}
            onChange={(e) => {
              handleChange(e);
            }}
            style={{ color: brandColor }}
            className={`rounded-full checked:bg-[${brandColor}] text-[${brandColor}] border focus:ring-0 bg-white disabled:bg-[#BABCC067]`}
          />
          <label className="ml-2 text-tiny md:text-sm font-bold">
            <p>
              <span className="text-[#F5222D] ">*</span>Please click here if forwarding address is
              unknown
            </p>
          </label>
        </div>

        <div>
          <div className="md:w-1/2 mt-5 md:mt-0 pt-2 pb-5 ">
            <label className="block text-sm font-bold mb-2">
              <p className="font-semibold text-xs md:text-sm">
                <span className="text-[#F5222D] ">*</span> Country
              </p>
            </label>

            {orderCountries.length && (
              <Controller
                control={control}
                name="countrySelected"
                defaultValue={orderCountries?.filter(({ countryCode }) => countryCode === 'US')[0]}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <DropDown
                    onChange={(e) => {
                      onChange(e);
                    }}
                    name={'countryName'}
                    value={value}
                    errors={errors}
                    errorName={'countrySelected'}
                    data={orderCountries}
                    disableForm={disable}
                  />
                )}
              />
            )}

            {errors?.countrySelected && (
              <p className="text-[#F5222D] text-[10px] pt-2">Please select a country</p>
            )}
          </div>
        </div>

        <div className="md:w-full mt-5 md:mt-0  ">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <p className=" font-semibold text-xs md:text-sm">
              <span className="text-[#F5222D]">*</span> Street Address
            </p>
          </label>
          {disableForm ? (
            <input
              className=" appearance-none border border-[#BABCC0] rounded w-full lg:w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
              type="text"
              aria-label="streetAddress"
              disabled={disableForm}
              placeholder="Please Enter..."
            />
          ) : (
            <input
              aria-label="streetAddress"
              className={
                errors?.street
                  ? ' appearance-none border border-[#F5222D] rounded w-full lg:w-[95%] py-2 px-3 '
                  : ' appearance-none border border-[#BABCC0] rounded w-full lg:w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:line-through disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none'
              }
              id="street"
              name="street"
              value={forwardingAddressForm?.street || ''}
              type="text"
              maxLength={55}
              disabled={disable}
              {...register('street', {
                required: !disable && 'Please enter Street Address',
                validate: {
                  maxLength: (value) => (!disable ? value.length <= 55 : 0),
                  validate: (value) => {
                    const invalidCharacters = /[?!*'\\/]/;
                    if (disable) return;
                    return (
                      !invalidCharacters.test(value) ||
                      'These special characters are not allowed:  / ? ! * ‘'
                    );
                  }
                }
              })}
              placeholder="Please Enter..."
            />
          )}

          {errors?.street && (
            <p className="text-[#F5222D] text-[10px] pt-2">{errors.street.message}</p>
          )}
        </div>
        {(Object.keys(countryItem).length > 0 && countryItem.countryCode !== 'US') ||
        forwardingAddressRes?.countrySelected?.countryCode !== 'US' ? (
          <div className="mt-5 mb-5">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <p className=" font-semibold text-xs md:text-sm">Address Line 2</p>
            </label>

            {disableForm ? (
              <input
                className=" appearance-none border border-[#BABCC0] rounded w-full lg:w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
                type="text"
                aria-label="addressLine2"
                disabled={disableForm}
                placeholder="Please Enter..."
              />
            ) : (
              <input
                aria-label="addressLine2"
                className={
                  errors?.addressLine2
                    ? ' appearance-none border border-[#F5222D] rounded w-full lg:w-[95%] py-2 px-3 '
                    : ' appearance-none border border-[#BABCC0] rounded w-full lg:w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:line-through disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none'
                }
                id="addressLine2"
                name="addressLine2"
                type="text"
                maxLength={55}
                disabled={disable}
                value={forwardingAddressForm?.addressLine2 || ''}
                {...register('addressLine2', {
                  validate: {
                    maxLength: (value = '') => (!disable ? value?.length <= 55 : 0),
                    validate: (value) => {
                      const invalidCharacters = /[?!*'\\/]/;
                      if (!disable && value?.length) {
                        return (
                          !invalidCharacters.test(value) ||
                          'These special characters are not allowed:  / ? ! * ‘'
                        );
                      }
                    }
                  }
                })}
                placeholder="Please Enter..."
              />
            )}

            {errors?.addressLine2 && (
              <p className="text-[#F5222D] text-[10px] pt-2">
                {errors?.addressLine2.message ?? 'Please enter Address 2'}
              </p>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="md:flex md:justify-between mt-5">
          <div className="md:w-[80%] w-full md:pr-7  ">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <p className=" font-semibold text-xs md:text-sm">
                <span className="text-[#F5222D]">*</span> City
              </p>
            </label>
            {disableForm ? (
              <input
                className=" appearance-none border border-[#BABCC0] rounded  w-full md:w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
                type="text"
                aria-label="city"
                disabled={disableForm}
                placeholder="Please Enter..."
              />
            ) : (
              <input
                className={
                  errors?.city
                    ? 'border border-[#F5222D]  rounded w-full md:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    : 'appearance-none border border-[#BABCC0]  rounded w-full md:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:line-through disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none'
                }
                id="city"
                aria-label="city"
                name="city"
                type="text"
                maxLength={30}
                disabled={disable}
                value={forwardingAddressForm?.city || ''}
                {...register('city', {
                  required: !disable && 'Please enter City',
                  maxLength: (value) => (!disable ? value.length <= 55 : 0),
                  validate: (value) => {
                    const invalidCharacters = /[?!*'\\/]/;
                    if (!disable && value?.length) {
                      return (
                        !invalidCharacters.test(value) ||
                        'These special characters are not allowed:  / ? ! * ‘'
                      );
                    }
                  }
                })}
                placeholder="Please Enter..."
              />
            )}
            {errors?.city && (
              <p className="text-[#F5222D] text-[10px] pt-2">{errors?.city.message}</p>
            )}
          </div>
          <div className="flex justify-around md:justify-start mt-5 md:mt-0 md:w-1/2 ">
            <div className="w-1/2 md:w-[50px]">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <p className=" font-semibold text-xs md:text-sm">
                  <span
                    className="text-[#F5222D]"
                    style={{
                      visibility:
                        (countryItem?.countryCode !== 'US' && countryItem.countryCode) ||
                        forwardingAddressRes?.countrySelected?.countryCode !== 'US'
                          ? 'hidden'
                          : 'visible'
                    }}>
                    *{' '}
                  </span>
                  State
                </p>
              </label>
              {disableForm ? (
                <input
                  className=" appearance-none border border-[#BABCC0] rounded   w-[60%] md:w-[55px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
                  type="text"
                  aria-label="state"
                  disabled={disableForm}
                  placeholder="AZ"
                />
              ) : (
                <input
                  className={
                    errors?.state
                      ? 'appearance-none border border-[#F5222D] rounded w-[60%] md:w-[55px]  py-2 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline uppercase'
                      : 'appearance-none border border-[#BABCC0] rounded w-[60%] md:w-[55px]  py-2 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:line-through disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none uppercase'
                  }
                  aria-label="state"
                  id="state"
                  name="state"
                  type="text"
                  value={forwardingAddressForm?.state || ''}
                  maxLength={
                    Object.keys(countryItem).length > 0 && countryItem.countryCode !== 'US' ? 3 : 2
                  }
                  disabled={disable}
                  {...register('state', {
                    required: getRequiredMessage(),
                    maxLength: (value) => {
                      if (value?.length) {
                        const maxLength =
                          Object.keys(countryItem)?.length > 0 && countryItem?.countryCode !== 'US'
                            ? 3
                            : 2;
                        return (
                          value.length <= maxLength ||
                          `Please enter a valid state. The state code must be at most ${maxLength} characters long.`
                        );
                      }
                    },
                    validate: {
                      validate: (value) => {
                        const invalidCharacters = /[?!*'\\/]/;
                        if (value.length < 2 && countryItem.countryCode === 'US') {
                          return 'Please enter a valid state. The state code must be at least 2 characters long';
                        }
                        if (value.length < 3 && countryItem.countryCode !== 'US') {
                          return 'Please enter a valid state. The state code must be at least 3 characters long';
                        }
                        if (!disable && value?.length) {
                          return (
                            !invalidCharacters.test(value) ||
                            'These special characters are not allowed:  / ? ! * ‘'
                          );
                        }
                      }
                    }
                  })}
                  placeholder={
                    Object.keys(countryItem)?.length > 0 && countryItem?.countryCode !== 'US'
                      ? ''
                      : 'AZ'
                  }
                />
              )}

              <div className=" w-[173%] md:w-[270%]  lg:w-[210%] z-10 pt-2 ">
                {errors?.state && (
                  <p className="text-[#F5222D] text-[10px] w-[55px] block pt-0 ">
                    {errors.state.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-1/2 md:w-[140px] lg:w-[140px] md:pl-5">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <p className=" font-semibold text-xs md:text-sm">
                  <span className="text-[#F5222D]">*</span> ZIP Code
                </p>
              </label>
              {disableForm ? (
                <input
                  className=" appearance-none border border-[#BABCC0] rounded w-[100%] md:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
                  id="zip"
                  aria-label="zip"
                  type="text"
                  disabled={disableForm}
                  placeholder="Please Enter..."
                />
              ) : (
                <>
                  <input
                    className={
                      (errors?.zip || (watchZip?.length === 0 && dirtyFields?.zip)) &&
                      watchZip?.toUpperCase() !== 'N/A'
                        ? ' appearance-none border border-[#F5222D] rounded w-[100%] md:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        : ' appearance-none border border-[#BABCC0] rounded w-[100%] md:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:line-through disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none'
                    }
                    aria-label="zip"
                    id="zip"
                    name="zip"
                    type="text"
                    maxLength={10}
                    value={forwardingAddressForm?.zip || ''}
                    disabled={disable}
                    {...register('zip', {
                      required: !disable && 'Please enter ZIP',
                      validate: {
                        maxLength: (value) => (!disable ? value.length <= 10 : 0),
                        validate: (value) => {
                          const invalidCharacters = /[?!*'\\]/;

                          if (value.length < minLengthZip) {
                            return `Please enter a valid ZIP Code for your region. If unknown, enter N/A. The ZIP Code must be at least ${minLengthZip} characters long.`;
                          }

                          if (!disable && value?.length) {
                            return (
                              !invalidCharacters.test(value) ||
                              'These special characters are not allowed:  / ? ! * ‘'
                            );
                          }
                        }
                      }
                    })}
                    placeholder="Please Enter..."
                  />

                  {(errors?.zip || (watchZip?.length === 0 && dirtyFields?.zip)) &&
                    watchZip?.toUpperCase() !== 'N/A' && (
                      <p className="text-[#F5222D] text-[10px] block pt-0  md:w-[300px] md:z-10 md:pt-2">
                        {errors?.zip?.message}
                      </p>
                    )}
                </>
              )}
            </div>
          </div>

          <div className="hidden md:block  md:w-[20%]"></div>
        </div>
      </div>
      <div className="xs:invisible md:visible">
        <StepButtons
          isValidForm={validateBtn()}
          handleBack={handleBack}
          handleStep={handleStep}
          confirmText="Next"
          cancelText="Previous"
        />
      </div>
    </div>
  );
};

export default ForwardingAddress;
