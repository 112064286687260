import React, { useEffect, useState } from 'react';
import ResidentItem from '../../stepper/occupantDetails/ResidentItem';
import PropTypes from 'prop-types';

const TableResidents = ({ responsableResident, idx }) => {
  const [counter, setCounter] = useState(1);

  const assingCountForResident = () => {
    idx === 0 ? setCounter(1) : setCounter(idx + (idx + 1));
  };

  useEffect(() => {
    assingCountForResident();
  }, []);

  return (
    <div className="md:flex-row md:flex flex-col mb-5">
      <ResidentItem responsableResident={responsableResident} counter={counter} idx={0} />
      <div className=" flex justify-items-center justify-center items-center lg:hidden md:hidden ">
        <div className=" border-b border-b-[#DCDDDF] w-full mb-5 mt-5"></div>
      </div>
      <div className="md:w-2/12"></div>
      <ResidentItem responsableResident={responsableResident} counter={counter + 1} idx={1} />
    </div>
  );
};

TableResidents.propTypes = {
  idx: PropTypes.number,
  responsableResident: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      guarantor: PropTypes.string
    })
  )
};

export default TableResidents;
