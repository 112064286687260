import React, { useContext, useEffect, useState } from 'react';
import Header from '../../shared/Header';
import { LeaseSelectSegment } from './components/LeaseSelectSegment';

import Loading from '../../shared/Loading';
import MessageDialog from '../../shared/MessageDialog';

import icon_error from '../../assets/img/icon_error.png';
import { UIContext } from '../../context/UIContext';

const SelectLeaseContainer = () => {
  const { getAuth, postAuth, loading, authLeases } = useContext(UIContext);
  const [loadingSelection, setLoadingSelection] = useState({ isLoading: false, leaseId: '' });
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [dialogMessage, setDialogMessage] = useState({
    title: '',
    message: '',
    icon: '',
    confirmMessage: ''
  });
  const handleSelectLease = async (leaseId) => {
    if (loadingSelection.isLoading) return;
    setLoadingSelection({ isLoading: true, leaseId });
    try {
      await postAuth(leaseId);
    } catch (error) {
      setOpenErrorDialog(true);
      setDialogMessage({
        title: 'Failed to Submit',
        message: `Sorry! Something went wrong. Please try again or select another lease.`,
        icon: icon_error,
        confirmMessage: 'Got it'
      });
    } finally {
      setLoadingSelection({ isLoading: false, leaseId: '' });
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  return (
    <div data-theme="avalon" className=" font-proxima xl:mb-8">
      <Header resident={authLeases.resident} />

      <div className="xs:max-w-screen-small md:max-w-screen-xl m-auto">
        <div className="grid h-screen place-items-center ">
          {loading ? (
            <Loading />
          ) : (
            authLeases?.leases !== undefined &&
            authLeases?.leases.length > 1 && (
              <>
                <div className="drop-shadow-md p-[24px] bg-white min-w-[60%]">
                  <h1 className="font-proxima font-bold text-[18px] text-black pt-1">
                    Multiple Accounts Detected
                  </h1>
                  <p className="font-proxima font-normal text-[18px] leading-[27px] pb-9 pt-9">
                    To view your Renewal Plan and Current Lease Details, please select from the
                    <br /> accounts below:
                  </p>
                  {authLeases?.leases?.map((lease, idx) => (
                    <div key={idx} className="pt-3 pb-3">
                      <LeaseSelectSegment
                        loadingSelection={loadingSelection}
                        leaseId={lease.leaseId}
                        communityName={lease.communityName}
                        handleSelectLease={handleSelectLease}
                      />
                    </div>
                  ))}
                </div>
              </>
            )
          )}
        </div>
      </div>
      <MessageDialog
        setOpenDialog={setOpenErrorDialog}
        openDialog={openErrorDialog}
        title={dialogMessage.title}
        message={dialogMessage.message}
        icon={dialogMessage.icon}
        confirmMessage="Got it!"
      />
    </div>
  );
};

export default SelectLeaseContainer;
