/* eslint-disable react/prop-types */
import React from 'react';

const CommunityDetailsHeader = ({ unit, community }) => {
  return (
    <div className="lg:flex xs:flex-row lg:mt-4 md:flex-col">
      <div className="lg:flex lg:justify-end">
        <p className=" font-proxima xs:text-[16px] xs:font-semibold inline-block  md:pt-2 md:text-[16px] my-auto lg:ml-1 lg:mr-3 lg:font-semibold">
          {community?.name}
        </p>
      </div>

      <div className="flex flex-col xs:mt-0 md:my-1 lg:flex-row  font-proxima">
        <p className="font-normal xs:text-[14px] lg:text-[14px] lg:pr-7 xs:pr-0">
          Apartment {unit?.buildingId}-{unit?.unitId}
        </p>
        <ul className="list-disc text-[14px] md:text-[14px] font-normal">
          <li className="xs:list-none lg:list-disc float-left mr-2 xs:mr-[22px]  md:mr-4">
            {unit?.bedroom === 'Studio'
              ? unit?.bedroom
              : unit?.bedroom > 1
                ? unit?.bedroom + ' bedrooms'
                : unit?.bedroom + ' bedroom'}
          </li>
          <li className="float-left mx-2 md:mr-4">
            {unit?.bathroom > 1 ? unit?.bathroom + ' baths' : unit?.bathroom + ' bath'}
          </li>
          <li className="float-left mx-[1rem] ">{unit?.squareFeet} sq.ft</li>
        </ul>
      </div>
    </div>
  );
};

export default CommunityDetailsHeader;
