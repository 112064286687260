import { useContext, useState, useEffect } from 'react';
import star from '../../../../../assets/img/yellowstar_icon.png';
import DropDown from '../../../../../shared/DropDown';
import { Controller } from 'react-hook-form';
import { UIContext } from '../../../../../context/UIContext';
import ToolTipInfo from '../../../../../shared/ToolTipInfo';
import info from '../../../../../assets/img/i-icon.png';
import { formatCurrency } from '../../../../../utils/commonMethods';

const LeaseTerminationSelection = ({ control, setValue, register, errors, watch }) => {
  const {
    formData,
    moveOutInformationRes,
    updateValidationForm,
    forwardingAddressRes,
    reasonToMoveOutRes
  } = useContext(UIContext);

  const infoText = `Continue to pay each month until 
  your apartment home is re-rented, 
  pending market conditions`;

  const [leaseTerminationForm, setLeaseTerminationForm] = useState({});

  const [fee, setFee] = useState();

  const updateForm = (data) => {
    const {
      agreementToSubmitAnEarlieTerminationPayment,
      leaseTerminationOption,
      confirmBalanceInAccount
    } = data;

    setLeaseTerminationForm({
      agreementToSubmitAnEarlieTerminationPayment,
      leaseTerminationOption,
      confirmBalanceInAccount
    });

    updateValidationForm({
      forwardingAddressRes,
      reasonToMoveOutRes,
      moveOutInformationRes: {
        ...data,
        selectedFee: moveOutInformationRes?.selectedFee
      }
    });

    setFee(moveOutInformationRes?.selectedFee?.fee);
  };

  useEffect(() => {
    setFee(moveOutInformationRes?.selectedFee?.fee || 0);
  }, [moveOutInformationRes?.selectedFee?.fee]);

  useEffect(() => {
    if (fee === 0) {
      setValue(
        'leaseTerminationOption',
        formData?.terminationOptions?.filter((term) => term.id === 2)[0] || ''
      );
    }
    if (fee !== 0 && moveOutInformationRes?.selectedFee?.fee) {
      setValue('leaseTerminationOption', '');
    }
  }, [fee, setValue]);

  useEffect(() => {
    const subscription = watch((data) => {
      const updatedData = {
        ...moveOutInformationRes,
        ...forwardingAddressRes,
        ...reasonToMoveOutRes,
        ...Object.keys(data).reduce((acc, key) => {
          if (data[key] !== undefined || typeof data[key] === 'object') {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      };

      updateForm(updatedData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, moveOutInformationRes]);

  return (
    <div className="pt-5">
      <div className="flex pb-5  items-center">
        <div>
          <img width={12} src={star} alt="star" />
        </div>
        <p className="text-xs font-bold pl-2 mt-[3px]">Wait & See</p>
        <div className="pl-1">
          <ToolTipInfo icon={info} text={infoText} />
        </div>
      </div>
      <div>
        <p className="font-proxima text-xs md:text-sm">
          <span className="font-bold"> Remain Rent Responsible</span> – After you submit this form,
          we will begin the process to market the apartment to re-rent. On the move-out date
          selected in this form, you must return keys, fobs and/or keycards and return the apartment
          back to us. You will remain liable for Rent and other charges through the earlier of your
          Lease End Date or until the date a new resident occupies and begins paying rent for your
          apartment. Subject to applicable law, you may be responsible for the rent differential
          through the Lease End Date if the apartment is re-rented at a lesser amount. Please refer
          to your lease agreement for any additional details.
        </p>
      </div>
      <div>
        <div className="md:w-1/3 mt-5 md:mt-5">
          <label className="block text-sm font-bold mb-2">
            <p className="font-semibold text-xs md:text-sm">
              {moveOutInformationRes?.selectedFee?.fee ? (
                <span className="text-[#F5222D] ">*</span>
              ) : (
                ''
              )}
              Lease Termination Selection
            </p>
          </label>
          <div className="w-[100%]">
            <Controller
              control={control}
              name="leaseTerminationOption"
              defaultValue={''}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  onChange={(e) => {
                    onChange(e);
                  }}
                  name={'name'}
                  value={value}
                  errors={errors}
                  errorName={'leaseTerminationOption'}
                  data={formData.terminationOptions}
                  disableForm={!moveOutInformationRes?.selectedFee?.fee}
                />
              )}
            />
          </div>
          {errors?.leaseTerminationOption && (
            <p className="text-[#F5222D] text-[10px] pt-2">Please select one option</p>
          )}
        </div>
      </div>
      {leaseTerminationForm?.leaseTerminationOption?.id === 1 && (
        <div>
          <div className="pt-5">
            <p className="font-proxima text-xs md:text-sm text-[#C64D5B] font-bold">
              *For early termination charge to be accepted, there must be no outstanding balances
              due on your account, and your account must be in good legal standing.
            </p>
            <p className="font-proxima text-xs md:text-sm text-[#C64D5B] font-bold pt-5">
              You can check your balance in AvalonAccess by selecting [Back to AvalonAccess] at the
              top of this form.
            </p>
            <p className="font-proxima text-xs md:text-sm text-black font-bold pt-6">
              I am making an offer to change my Lease End Date. For my offer to be accepted:
            </p>
          </div>
          <div>
            <div className="flex items-center mt-5  md:mt-10">
              <input
                type="checkbox"
                id="agreementToSubmitAnEarlieTerminationPayment"
                aria-label="agreementToSubmitAnEarlieTerminationPayment"
                name="agreementToSubmitAnEarlieTerminationPayment"
                value={moveOutInformationRes?.agreementToSubmitAnEarlieTerminationPayment || false}
                style={{ color: ' #C64D5B', borderColor: ' #C64D5B' }}
                {...register('agreementToSubmitAnEarlieTerminationPayment', { required: true })}
                className={
                  errors?.agreementToSubmitAnEarlieTerminationPayment
                    ? 'appearance-none h-4 w-4  border border-[#F5222D]'
                    : `appearance-none h-4 w-4  border border-[#C64D5B]  checked:bg-[#C64D5B] text-[#C64D5B] focus:ring-0 disabled:bg-[#BABCC067]`
                }
              />
              <label className="ml-2 text-tiny md:text-sm ">
                <p>
                  <span className="text-[#F5222D] font-bold">*</span>I agree to submit an Early
                  Termination payment of{' '}
                  <span className="text-[#F5222D] font-bold">
                    ${formatCurrency(moveOutInformationRes?.selectedFee?.fee)}
                  </span>{' '}
                  within 24 hours.
                </p>
              </label>
            </div>
          </div>
          <div>
            <div className="flex items-center mt-5">
              <input
                type="checkbox"
                id="confirmBalanceInAccount"
                aria-label="confirmBalanceInAccount"
                name="confirmBalanceInAccount"
                value={moveOutInformationRes?.confirmBalanceInAccount || false}
                style={{ color: '#C64D5B', borderColor: '#C64D5B' }}
                {...register('confirmBalanceInAccount', { required: true })}
                className={
                  errors?.confirmBalanceInAccount
                    ? 'appearance-none h-4 w-4  border border-[#F5222D]'
                    : `appearance-none h-4 w-4  border border-[#C64D5B]  checked:bg-[#C64D5B] text-[#C64D5B] focus:ring-0 disabled:bg-[#BABCC067]`
                }
              />
              <label className="ml-2 text-tiny md:text-sm ">
                <p>
                  <span className="text-[#F5222D] font-bold">*</span>I confirm there is a{' '}
                  <span className="text-[#F5222D] font-bold"> $0 balance </span>on my account prior
                  to submitting my offer to change my Lease End Date.
                </p>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaseTerminationSelection;
