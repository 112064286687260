import axios from 'axios';
import { getToken } from '../utils/commonMethods';

/* eslint-disable no-undef */
const BASE_URL = process.env.REACT_APP_NOTICE_TO_VACATE_API;
const API_KEY = process.env.REACT_APP_NOTICE_TO_VACATE_API_KEY;

export const noticeToVacateAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: getToken(),
    'x-api-key': API_KEY
  }
});
