import { useContext } from 'react';
import { UIContext } from '../context/UIContext';

const ProgressLine = () => {
  const { formData } = useContext(UIContext);
  const { community } = formData;

  const renderProgressAnimation = (brand) => {
    const PROGRESS_ANIMATION = {
      ava: (
        <div className="w-full h-full animate-indeterminateAnimation origin-[0%_50%] bg-[#E40087] text-primary"></div>
      ),
      avalon: (
        <div className="w-full h-full animate-indeterminateAnimation origin-[0%_50%] bg-[#C64D5B] text-primary"></div>
      ),
      eaves: (
        <div className="w-full h-full animate-indeterminateAnimation origin-[0%_50%] bg-[#51832A]"></div>
      )
    };

    const DEFAULT_COLOR = brand ? (
      PROGRESS_ANIMATION[brand]
    ) : (
      <div className="w-full h-full animate-indeterminateAnimation origin-[0%_50%] bg-[#194569]"></div>
    );
    const progressBarColor = PROGRESS_ANIMATION[brand] || DEFAULT_COLOR;
    return progressBarColor;
  };

  return (
    <>
      {
        <div className=" xs:w-[200px] md:w-[350px] m-auto">
          <div className="h-[4px] bg-slate-400 w-full overflow-hidden">
            {renderProgressAnimation(community?.brand)}
          </div>
        </div>
      }
    </>
  );
};

export default ProgressLine;
