const StepButtons = ({
  isValidForm,
  handleBack,
  handleStep,
  cancelText = 'Cancel',
  confirmText = 'Confirm'
}) => {
  return (
    <div className="flex justify-around md:justify-end mt-5 md:text-sm xs:text-xs font-proxima font-semibold   lg:mt-5 mb-5 ">
      <div className="md:mr-5">
        <button
          onClick={handleBack}
          type="button"
          className="bg-white w-[120px] h-[44px] lg:w-[141px] lg:h-[40px]  hover:border-primary hover:text-primary">
          {cancelText}
        </button>
      </div>
      <button
        className={` ${isValidForm ? 'bg-primary hover:bg-hover' : 'bg-[#CACED8] cursor-not-allowed'} xs:w-[100px] xs:h-[40px]  md:w-[120px] md:h-[44px] lg:w-[141px] lg:h-[40px] text-contrast rounded-md`}
        onClick={handleStep}
        disabled={!isValidForm}
        aria-label="submitBtn">
        {confirmText}
      </button>
    </div>
  );
};

export default StepButtons;
