/* eslint-disable no-undef */
const Breadcrumb = () => {
  return (
    <>
      <div className="lg:flex hidden items-center">
        <h2 className=" font-proxima  hidden lg:[display:block] ">
          <a
            className="text-[#31343A] font-bold"
            href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}>
            My Lease Dashboard
          </a>
        </h2>
        <svg
          className="hidden h-5 w-5   lg:[display:block]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <h2 className="font-proxima text-sm font-semibold  text-[#707070] hidden lg:[display:block]">
          Notice To Vacate
        </h2>
      </div>
      <div className="flex lg:hidden items-center">
        <svg
          className="h-4 w-5 inline rotate-180 "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        <a href={`${process.env.REACT_APP_RENEWALS_URL}/dashboard`}>
          <p className="font-proxima text-[14px]">Back</p>
        </a>
      </div>
    </>
  );
};

export default Breadcrumb;
