import { createContext, useContext, useEffect, useState } from 'react';

const viewportContext = createContext({});

// eslint-disable-next-line react/prop-types
export function ViewportProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', debounce(handleWindowResize, 100));
    return () => window.removeEventListener('resize', debounce(handleWindowResize, 100));
  }, []);

  return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
}

export function useViewport() {
  const { width, height } = useContext(viewportContext);
  return { width, height };
}

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
