/* eslint-disable no-unused-vars */
import { useEffect, useRef, useContext, useState } from 'react';
import Welcome from '../../shared/Welcome';
import ImportantDates from '../../shared/ImportantDates';
import NoticeToVacateForm from './NoticeToVacateForm';
import { UIContext } from '../../context/UIContext';
import Header from '../../shared/Header';
import Loading from '../../shared/Loading';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { getToken } from '../../utils/commonMethods';

const FormContainer = () => {
  const dateParentRef = useRef();

  const { getFormData, loading, formData, hasMultipleLeases, hasSelectedLease } =
    useContext(UIContext);
  const { community, lease, resident, unit } = formData;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    getFormData();
    document.body.style.backgroundColor = '#F6F6F7';
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', community?.brand);
  }, [community?.brand]);

  useEffect(() => {
    const storedHasMultipleLeases = localStorage.getItem('hasMultipleLeases') === 'true';

    if (storedHasMultipleLeases && !hasSelectedLease) {
      navigate('../', { replace: true });
    }
  }, [hasSelectedLease]);

  const handleCurrentStep = (step) => {
    if (isNaN(step)) {
      return setCurrentStep(0);
    }
    setCurrentStep(step);
  };

  useEffect(() => {
    if (!resident || !lease) return;

    if (window.pendo)
      window.pendo.initialize({
        visitor: {
          accountId: lease?.leaseId,
          firstName: resident?.firstName,
          lastName: resident?.lastName,
          userId: jwtDecode(getToken())?.username,
          decideBy: lease?.decideBy,
          leaseExpirationDate: lease?.leaseExpirationDate,
          leaseTerm: lease?.leaseTerm,
          email: resident?.email,
          experience: 'Renewals',
          bedroom: unit?.bedroom,
          communityBrand: community?.brand,
          communityCode: resident?.communityCode,
          communityName: community?.name,
          communityState: lease?.leaseId?.slice(0, 2)
        },
        acccount: {
          accountId: resident?.leaseId
        }
      });
  }, [lease, resident]);

  return (
    <div data-theme={community?.brand}>
      <div className="overflow">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Header />
            <Welcome />
            <div className="max-w-[1400px] m-auto mt-5 bg-page">
              <div className="p-5 lg:px-0 md:px-10 lg:py-[25px] flex flex-col lg:flex-row  font-proxima box-border ">
                <div className="lg:p-0  gap-0  flex flex-col lg:justify-end flex-1 lg:flex-row-reverse md:flex-row-reverse ">
                  <div
                    className={` h-[357px] md:mt-5  lg:p-0 md:p-0 float-right  bg-[#FFF7E6] flex-1 shadow-lg md:z-10 ${currentStep === 0 ? 'block ' : 'hidden'}`}
                    ref={dateParentRef}>
                    <ImportantDates dateParentRef={dateParentRef} setOpen={setOpen} open={open} />
                  </div>
                  <div
                    className={`md:h-[2%] md:w-[100%] ${currentStep === 0 ? 'hidden' : 'md:block xs:hidden lg:hidden'} `}></div>

                  <div
                    className={`w-[100%] md:w-[55%] ${currentStep !== 0 ? 'lg:w-[100%] lg:pr-12' : 'lg:w-[75%]'} md:pr-12 pt-5 lg:pt-0 md:pt-0`}>
                    <NoticeToVacateForm
                      isOpen={open}
                      currentStep={currentStep}
                      handleCurrentStep={handleCurrentStep}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FormContainer;
