/* eslint-disable no-undef */
import { Fragment, useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import backTo from '../assets/img/backTo.png';
import user from '../assets/img/user-circle.png';
import { useLocation } from 'react-router';

import { UIContext } from '../context/UIContext';

const AccessMenu = () => {
  const { formData, logout, authLeases } = useContext(UIContext);
  const { lease, resident } = formData;
  const [open, setOpen] = useState(false);

  const [displaySwitchLease, setDisplaySwitchLease] = useState(false);

  const location = useLocation();
  useEffect(() => {
    localStorage.getItem('hasMultipleLeases') == 'true'
      ? setDisplaySwitchLease(true)
      : setDisplaySwitchLease(false);
  }, []);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <Menu
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      as="div"
      className="md:flex justify-end md:border-none lg:border-l-2">
      <Menu.Button>
        <div className="md:hidden relative overflow-hidden">
          <img src={user} alt="user avatar" width={20} height={20} />
        </div>
      </Menu.Button>
      <Menu.Button className="hidden md:inline-flex md:items-center justify-center pl-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary">
        <div className="relative ml-4 overflow-hidden">
          <img src={user} alt="user avatar" width={28} height={28} />
        </div>
        {
          <>
            <div
              aria-label="userName"
              className="hidden lg:block ml-4 space-y-1 font-proxima text-[14px] font-medium dark:text-white text-left">
              {authLeases?.leases?.length ? (
                <div>
                  {authLeases?.resident?.firstName} {authLeases?.resident?.lastName}{' '}
                </div>
              ) : (
                <div>
                  {resident?.firstName} {resident?.lastName}{' '}
                </div>
              )}
              <div className="text-[14px] font-proxima font-medium text-gray-500 dark:text-gray-400">
                {lease?.leaseId}
              </div>
            </div>
            <ChevronDownIcon
              className="md:hidden lg:flex my-auto -mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </>
        }
      </Menu.Button>

      {(resident || authLeases?.leases?.length) && (
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <div className="relative z-10">
            <Menu.Items className="absolute right-0 md:mt-16 w-56 rounded-md font-proxima shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1" data-testid={'logout-button'}>
                <Menu.Item className="lg:hidden md:block">
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block pl-[15px] py-2 text-sm'
                      )}>
                      <div className="md:text-sm xs:text-[14px]">
                        {/* {authLeases?.resident?.firstName} {authLeases?.resident?.lastName}{' '} */}
                        &nbsp;{resident?.firstName} {resident?.lastName.charAt(0)}.
                      </div>
                      <div className="text-[10px] font-bold">&nbsp;{'Acct: ' + lease?.leaseId}</div>
                    </div>
                  )}
                </Menu.Item>
                {displaySwitchLease && location.pathname == '/form' && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={'/'}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-[14px] cursor-pointer'
                        )}>
                        <div className="flex justify-start items-center">
                          <img src={backTo} className="w-[10px] h-[10px] mr-2" alt="backTo" />
                          <div>Switch Your Lease</div>
                        </div>
                      </a>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={process.env.REACT_APP_AVALON_ACCESS_URL}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-[14px]'
                      )}>
                      &#8592;&nbsp;Back to Avalon Access
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={logout}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-[14px]'
                      )}>
                      &#x5b;&#8592;&nbsp;Sign Out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      )}
    </Menu>
  );
};

export default AccessMenu;
