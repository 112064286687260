import { useContext } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Breadcrumb from './Breadcrumb';
import { UIContext } from '../context/UIContext';
import CommunityDetailsHeader from './CommunityDetailsHeader';

dayjs.extend(advancedFormat);

const Welcome = () => {
  const { formData } = useContext(UIContext);
  const { unit, community } = formData;
  return (
    <div className="mx-auto bg-white w-full drop-shadow-lg xs:p-0 xs:px-1 lg:px-10  lg:py-[5px] ">
      <section className="max-w-screen-xl w-full md:w-[89%] xl:w-full my-0 mx-auto py-1 px-2 md:px-0">
        <div className="flex justify-between items-center lg:h-[40px] xs:h-auto">
          <div>
            <Breadcrumb />
          </div>
          <div className="xs:hidden lg:flex">
            <CommunityDetailsHeader unit={unit} community={community} />
          </div>
        </div>
        <div className=" justify-between ">
          <h3 className="font-header font-normal text-primary text-[27px] lg:text-title mt-1 md:text-[27px]">
            Notice To Vacate
          </h3>
        </div>
        <div className="xs:flex lg:hidden">
          <CommunityDetailsHeader unit={unit} community={community} />
        </div>
      </section>
    </div>
  );
};

export default Welcome;
