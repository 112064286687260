import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import PropTypes from 'prop-types';
import { UIContext } from '../context/UIContext';

const MessageDialog = ({
  setOpenDialog,
  openDialog,
  confirmMessage,
  title,
  subtitle,
  message,
  icon
}) => {
  const { formData } = useContext(UIContext);
  const { community } = formData;
  return (
    <Transition.Root show={openDialog} as={Fragment}>
      <Dialog
        data-theme={community?.brand}
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          setOpenDialog(false);
        }}
        open>
        <Dialog.Overlay
          className={'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'}></Dialog.Overlay>
        <div className="box-border w-auto lg:w-[423px] md:w-[407px] bg-white shadow-lg p-0 rounded-[4px] m-auto mx-5 md:mx-auto mt-[15%] transform transition-all border-boxborder font-proxima h-auto text-center max-w-[100%]">
          <div className="p-3 md:px-5 border-b border-b-border2">
            <button
              className="float-right outline-0 text-[#979AA0]"
              onClick={() => setOpenDialog(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 m-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <img src={icon} className="w-[1.5rem] mr-3 float-left" alt="success" />
            <h2 className="align-left text-xl font-bold text-left">{title}</h2>
          </div>
          {subtitle ? (
            <>
              <div className="text-left text-base font-bold p-0  md:pl-[18px] md:pt-[10px]">
                {subtitle}{' '}
              </div>
              <div className="text-left text-sm text-black md:pt-1 md:px-5">{message}</div>
            </>
          ) : (
            <div className="text-left text-sm text-black p-3 md:p-5">{message}</div>
          )}
          <div className="pb-[1rem] pt-[0.25rem]">
            <button
              className={`${
                community?.brand !== undefined ? 'bg-primary hover:bg-hover' : 'bg-[#1D2F5C]'
              } text-white w-[182px] rounded-[3px] h-[32px] text-center my-3`}
              onClick={() => setOpenDialog(false)}>
              <div className="flex items-center justify-center flex-1"> {confirmMessage} </div>
            </button>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

MessageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  confirmMessage: PropTypes.string,
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool
};

export default MessageDialog;
