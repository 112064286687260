import { useContext, useEffect } from 'react';
import { UIContext } from '../../../../../context/UIContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { formatCurrency } from '../../../../../utils/commonMethods';

dayjs.extend(utc);

const getClosestDate = (data, targetDate) => {
  const target = dayjs.utc(targetDate).valueOf();

  return data.reduce((closest, { period }) => {
    const start = dayjs.utc(period.moveOutStart).valueOf();
    const end = dayjs.utc(period.moveOutEnd).valueOf();

    const closestDateInRange = Math.abs(target - start) < Math.abs(target - end) ? start : end;

    return !closest || Math.abs(target - closestDateInRange) < Math.abs(target - closest.valueOf())
      ? dayjs.utc(closestDateInRange)
      : closest;
  }, null);
};

const TableLeaseTermination = ({ moveOutSelection }) => {
  const {
    formData,
    forwardingAddressRes,
    reasonToMoveOutRes,
    moveOutInformationRes,
    updateValidationForm
  } = useContext(UIContext);

  const closestDate = getClosestDate(formData.earlyTermination.fees, moveOutSelection);

  const findMatchingFee = (fees, closestDate) => {
    return fees.find((fee) => {
      const feeStart = dayjs.utc(fee.period.moveOutStart);
      const feeEnd = dayjs.utc(fee.period.moveOutEnd);

      const isClosest = closestDate && (closestDate.isSame(feeStart) || closestDate.isSame(feeEnd));

      return isClosest;
    });
  };

  const matchingFee = findMatchingFee(formData.earlyTermination.fees, closestDate);

  useEffect(() => {
    if (matchingFee) {
      updateValidationForm({
        forwardingAddressRes,
        reasonToMoveOutRes,
        moveOutInformationRes: {
          ...moveOutInformationRes,
          selectedFee: matchingFee
        }
      });
    }
  }, [matchingFee, reasonToMoveOutRes, forwardingAddressRes]);

  return (
    <div>
      <table className="w-full">
        <thead className="bg-[#0000001F] xs:text-xs  md:text-[16px] font-semibold">
          <tr>
            <th className="text-left p-2">Move-Out Period</th>
            <th className="text-left p-2">Early Termination Charge</th>
          </tr>
        </thead>
        <tbody>
          {formData.earlyTermination.fees.map((fee) => {
            const feeStart = dayjs.utc(fee.period.moveOutStart);
            const feeEnd = dayjs.utc(fee.period.moveOutEnd);
            const isClosest =
              closestDate && (closestDate.isSame(feeStart) || closestDate.isSame(feeEnd));
            return (
              <tr
                key={fee?.period.moveOutEnd}
                className={`h-[52px] xs:text-small md:text-sm ${isClosest && moveOutInformationRes?.leaseTerminationOption?.id !== 2 ? 'bg-selectBackgroundcolor' : 'bg-[transparent]'}`}>
                <td className="p-2">{`${feeStart.format('M/DD/YYYY')} - ${feeEnd.format('M/DD/YYYY')}`}</td>
                <td className="text-left p-2">
                  {fee?.fee
                    ? `$${formatCurrency(fee.fee)}`
                    : 'Remain Rent Responsible by paying through lease expiration date'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableLeaseTermination;
