import React from 'react';
import PropTypes from 'prop-types';
import SpinnerIcon from '../../../shared/SpinnerIcon';

export const LeaseSelectSegment = ({
  communityName,
  leaseId,
  handleSelectLease,
  loadingSelection
}) => {
  return (
    <div
      className="border border-black  bg-white rounded-[4px] cursor-pointer "
      onClick={() => handleSelectLease(leaseId)}>
      <div
        className={`flex justify-center p-4 items-center font-proxima font-bold text-black text-base ${
          loadingSelection.isLoading &&
          loadingSelection.leaseId === leaseId &&
          'md:h-[50px] w-[100%]  h-[50px]  flex justify-center items-center focus:outline-none pointer-events-none'
        }`}>
        {loadingSelection.isLoading && loadingSelection.leaseId === leaseId ? (
          <div className="w-[100px] h-[90px] lg:top[-32px] md:-2 flex justify-center items-center align-middle">
            <SpinnerIcon colorFill={'ava'} />
          </div>
        ) : (
          <span className="flex justify-center text-[16px]">
            <div className="pr-5">
              <p>{communityName}</p>
            </div>
            <div>
              <p className="uppercase">{leaseId}</p>
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

LeaseSelectSegment.propTypes = {
  communityName: PropTypes.string.isRequired,
  leaseId: PropTypes.string.isRequired,
  handleSelectLease: PropTypes.func.isRequired,
  loadingSelection: PropTypes.object
};
