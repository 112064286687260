/* eslint-disable no-unused-vars */
import { useRef, useState, useContext, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import LeaseEnds_Icon from '../assets/img/lease_ends_icon.png';
import MoveOut_icon from '../assets/img/moveout-icon.png';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import DecideByDate_icon from '../assets/img/decidebydate-icon.png';
import info from '../assets/img/i-icon.png';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { UIContext } from '../context/UIContext';

dayjs.extend(relativeTime);

const ImportantDates = ({ dateParentRef, open, setOpen }) => {
  const { formData } = useContext(UIContext);
  const { lease, community } = formData;

  const datesRef = useRef();
  const [position, setPosition] = useState({
    clientX: '700',
    clientY: '350'
  });
  const togglePopup = () => {
    const pos = dateParentRef?.current?.getBoundingClientRect();
    setOpen(true);
    setPosition({
      top: pos.top,
      left: pos.left
    });
  };

  const getLeaseEndDays = () => {
    if (lease) {
      return dayjs(lease?.leaseExpirationDate).diff(dayjs().format('YYYY-MM-DD'), 'day');
    } else return 0;
  };

  const getLeaseExpiryDate = (expiryDate, noticePeriod) => {
    return expiryDate && dayjs(expiryDate).subtract(noticePeriod, 'days').format('MMMM D, YYYY');
  };

  const remainingDays =
    dayjs(lease && lease.leaseExpirationDate)
      .endOf('month')
      .date() - dayjs(lease && lease.leaseExpirationDate).date();

  const days = getLeaseEndDays();
  const isHideLeaseEndDate = formData?.isHideLeaseEndDate;

  const getExpiryMessage = (days, isHideLeaseEndDate) => {
    const relativeDate = dayjs().add(days, 'day').fromNow(true); // 'in X days' or 'X days ago'

    if (days < 0) {
      return isHideLeaseEndDate ? (
        <>
          Your current rate expired <span className="font-bold">{relativeDate}</span> ago
        </>
      ) : (
        <>
          Your current Lease expired <span className="font-bold">{relativeDate}</span> ago
        </>
      );
    } else {
      return isHideLeaseEndDate ? (
        <>
          Your current rate expires in <span className="font-bold">{relativeDate}</span>
        </>
      ) : (
        <>
          Your current Lease will end in <span className="font-bold">{relativeDate}</span>
        </>
      );
    }
  };

  return (
    <div>
      {!open && (
        <>
          <div
            className="bg-light-pink rounded border-[1px] border-[#ADACA9] h-[357px]"
            ref={datesRef}>
            <div className="font-proxima text-primary text-base flex justify-items-center justify-center items-center my-4 pl-[5%] ">
              <h2 className="text-[#31343A] text-[20px]  font-semibold">Important Dates</h2>
            </div>

            <div>
              <div className="flex justify-items-center justify-center items-center mt-5 mb-3">
                <div className="mt-5 mb-5 mr-5 md:ml-1  md:mr-3 ml-0">
                  <img src={MoveOut_icon} className="w-[24px] float-right" alt="move_out_icon" />
                </div>
                <div className="bg-white w-[210px]  border rounded border-gray-border  ">
                  <div className="flex-col m-2 ">
                    <p className="font-proxima text-small flex whitespace-nowrap">
                      Decide to Renew or Move Out By:
                    </p>
                    <div className="font-proxima text-sm text-primary font-bold flex justify-center items-center">
                      {lease && getLeaseExpiryDate(lease.leaseExpirationDate, lease.noticeToVacate)}
                      <div className=" flex items-center self-center rounded-[2px] group relative p-[2px] ml-[2px] md:ml-[8px]">
                        <div className="inline-block">
                          <img
                            src={info}
                            className="w-[12px] cursor-pointer md:w-[12px] max-w-[12px]"
                            alt="icon_confirm"
                          />
                        </div>
                        <span
                          className={`md:h-auto font-proxima font-bold md:w-[245px]  text-[12px] leading-[15px]
                      text-left absolute hidden  group-hover:flex md:-top-[3rem]  xs:-top-[5.5rem] xs:-right-[-4rem] small:-right-[-7rem]
                      translate-x-full px-2 py-1 bg-white shadow-md rounded-[2px] text-[#31343A]  before:content-['']
                      before:absolute before:-bottom-4 before:-rotate-90 xs:before:right-[55%]
                      small:before:right-[50%]  before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-white`}>
                          <p className="w-[125px] md:w-[245px]">
                            Moving out? Submit your Notice to Vacate by this date to avoid
                            additional charges.
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-items-center justify-center items-center mb-3">
                <div className="mt-5 mb-5 mr-5 md:ml-1  md:mr-3 ml-0">
                  <img src={DecideByDate_icon} className="w-[24px] float-right" alt="decide_icon" />
                </div>
                <div className="bg-white w-[210px]  border rounded border-gray-border">
                  <div className="flex-col m-2 ">
                    <p className="font-proxima text-small flex  justify-center items-cente">
                      Sign Renewal By:
                    </p>
                    <div className="font-proxima text-sm font-bold text-primary flex justify-center md:pl-[7%]">
                      {dayjs(lease && lease.decideBy).format('MMMM D, YYYY')}
                      <div className=" flex items-center self-center h-[18px] rounded-[2px] group relative p-[2px] ml-[2px] md:ml-[8px]">
                        <div className="inline-block">
                          <img
                            src={info}
                            className="w-[12px] cursor-pointer md:w-[12px] max-w-[12px]"
                            alt="icon_confirm"
                          />
                        </div>
                        <span
                          className={`${
                            formData?.blockMtm ? 'md:h-auto' : 'md:h-[50px]'
                          } font-proxima font-bold md:w-[225px]  text-[12px] leading-[15px]
                      text-left absolute hidden group-hover:flex  md:-top-[3.5rem] xs:-top-[5.5rem] xs:-right-[-4rem] small:-right-[-6rem]
                      translate-x-full px-2 py-1 bg-white shadow-md rounded-[2px] text-[#31343A]  before:content-['']
                      before:absolute before:-bottom-4 before:-rotate-90 xs:before:right-[55%]
                      small:before:right-[50%]  before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-white`}>
                          <p className="w-[125px] md:w-[225px]">
                            {formData?.blockMtm
                              ? 'Sign your renewal by this date to ensure accurate billing for the month in which your new lease starts.'
                              : 'Sign your renewal by this date to avoid being charged a month-to-month rate.'}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-items-center justify-center items-center mb-3 ">
              <div className="mt-5 mb-5 mr-5 md:ml-1  md:mr-3 ml-0">
                <img src={LeaseEnds_Icon} className="w-[24px] float-right" alt="lease_icon" />
              </div>
              <div className="bg-white w-[210px] border rounded border-gray-border ">
                <div className="flex-col m-2">
                  <p className="font-proxima text-small flex  justify-center items-center ">
                    {formData?.isHideLeaseEndDate ? 'Rate Expires:' : 'Lease Ends:'}
                  </p>
                  <p className="font-proxima text-sm  text-primary font-bold flex  justify-center items-center">
                    {dayjs(lease?.leaseExpirationDate).format('MMMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-2 flex justify-center content-center pl-[15%] ">
              <p
                className="underline text-primary font-semibold text-[15px] cursor-pointer"
                onClick={togglePopup}>
                View More Details
              </p>
              <div onClick={togglePopup} className="cursor-pointer text-primary">
                <ChevronDownIcon
                  className="my-auto -mr-1 ml-1 h-5 w-4 -rotate-90"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {open && (
        <div className="border-[1px] border-[#ADACA9]">
          <div
            id="importantDatesDialog"
            className=" lg:w-[348px] pt-4 bg-[#FFF7E6] transform transition-all font-proxima text-primary">
            <div>
              <div className="pr-3">
                <button
                  className="float-right border border-black rounded-full outline-0 text-black"
                  onClick={() => setOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 m-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <h2 className="align-left xs:pl-[16%] small:pl-[11%] lg:pl-[12%] text-base font-semibold text-center text-[#31343A]">
                Important Dates
              </h2>
            </div>
            <div className="bg-light-pink rounded  px-3 pb-2 text-center   xs:pr-[2rem] xs:pl-[2rem]  small:pr-[2.75rem] small:pl-[3.75rem]  md:pr-[1.75rem] md:pl-[2rem] ">
              <div className=" pb-5 w-auto lg:mx-1  border-b border-b-black ">
                <div className="flex flex-row py-5">
                  <div className="text-black flex items-center pr-4">
                    <img src={MoveOut_icon} className="w-[24px] " alt="box_icon" />
                  </div>
                  <div className="border rounded border-gray-border bg-white text-center ml-0 py-2 flex flex-col w-[252px] md:w-[252px]">
                    <label className="text-black text-[13px] whitespace-nowrap inline-block">
                      Decide to Renew or Move Out By:
                    </label>
                    <label className="text-[16px] font-bold">
                      {lease && getLeaseExpiryDate(lease.leaseExpirationDate, lease.noticeToVacate)}
                    </label>
                  </div>
                </div>
                <p className="text-black font-proxima font-bold text-[16px] pt-1 pb-2">
                  We’d love for you to stay with us!
                </p>
                <p className="text-black text-left font-normal text-[14px]  md:leading-[17px]">
                  If you do not plan to renew, please alert AvalonBay and submit a{' '}
                  <span className="font-semibold">Notice To Vacate</span> at least{' '}
                  <span className="font-semibold">{lease && lease.noticeToVacate} days</span> before
                  your {formData?.isHideLeaseEndDate ? ' rate expires ' : ' lease expires '} to
                  avoid incurring additional charges.
                </p>
              </div>
              <div className="pb-5 w-auto md:mx-1 border-b border-b-black">
                <div className="flex flex-row mb-2 py-5">
                  <div className="text-black flex items-center pr-4">
                    <img src={DecideByDate_icon} className="w-[24px] " alt="decide_icon" />
                  </div>
                  <div className="border rounded border-gray-border bg-white md:w-[252px]  text-center ml-0 p-2 flex flex-col w-[252px]">
                    <label className="text-black text-[13px]">Sign Renewal By:</label>
                    <label className="text-[16px] font-bold ">
                      {dayjs(lease && lease.decideBy).format('MMMM D, YYYY')}
                    </label>
                  </div>
                </div>
                <div className="text-black text-left text-xs md:leading-5">
                  {dayjs(lease && lease.leaseExpirationDate).format('MMMM D, YYYY') ==
                  dayjs(lease && lease.decideBy).format('MMMM D, YYYY') ? (
                    <div className="text-black font-normal text-left text-[14px]  md:leading-[17px]">
                      If you sign your renewal by{' '}
                      {dayjs(lease && lease.decideBy).format('MMMM D, YYYY')}, your billing
                      statement on{' '}
                      {dayjs(lease && lease.leaseExpirationDate)
                        .startOf('month')
                        .add(1, 'month')
                        .format('MMMM Do')}{' '}
                      will be based on your renewal rate.
                      <div className="h-[10px]"></div>
                      <span>
                        {' '}
                        {formData?.blockMtm || formData.isHideLeaseEndDate ? (
                          <span>
                            <span className="font-bold"> To ensure accurate billing </span>for the
                            month in which your new lease starts, sign your renewal by{' '}
                            {dayjs(lease && lease.decideBy).format('MMMM D, YYYY')}.
                          </span>
                        ) : (
                          <span>
                            If you do not sign your renewal by{' '}
                            {dayjs(lease?.decideBy).format('MMMM D, YYYY')},{' '}
                            <span className="font-bold">
                              you will be billed the month-to-month rate
                            </span>{' '}
                            starting{' '}
                            <span>
                              {dayjs(lease && lease.decideBy)
                                .add(1, 'month')
                                .startOf('month')
                                .format('MMMM D, YYYY')}
                              .
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                  ) : (
                    <div className="text-black font-normal text-left text-[14px]  md:leading-[17px]">
                      If you sign your renewal by {dayjs(lease?.decideBy).format('MMMM D, YYYY')},{' '}
                      your billing statement on{' '}
                      {dayjs(lease && lease.leaseExpirationDate)
                        .startOf('month')
                        .format('MMMM Do')}{' '}
                      will be a prorated amount to reflect the first{' '}
                      {dayjs(lease && lease.leaseExpirationDate).date()}{' '}
                      {dayjs(lease && lease.leaseExpirationDate).date() > 1 ? 'days' : 'day'} at
                      your current lease rate and the remaining {remainingDays}{' '}
                      {remainingDays > 1 ? 'days' : 'day'} at your renewal rate.
                      <div className="h-[10px]"></div>
                      <span>
                        {' '}
                        {formData?.blockMtm ? (
                          <span>
                            <span className="font-bold"> To ensure accurate billing </span>for the
                            month in which your new lease starts, sign your renewal by{' '}
                            {dayjs(lease && lease.decideBy).format('MMMM D, YYYY')}.
                          </span>
                        ) : (
                          <span>
                            If you do not sign your renewal by{' '}
                            {dayjs(lease?.decideBy).format('MMMM D, YYYY')},{' '}
                            <span className="font-bold">
                              {' '}
                              you will be billed the month-to-month rate{' '}
                            </span>{' '}
                            for the days after your current lease ends.
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className=" pb-5 w-auto lg:mx-1 pt-5">
                <div className="flex flex-row mb-2 ">
                  <div className="text-black flex items-center pr-4">
                    <img src={LeaseEnds_Icon} className="w-[24px]" alt="calendat_icon" />
                  </div>
                  <div className="border rounded border-gray-border md:w-[252px]  bg-white text-center ml-0 p-2 flex flex-col w-[252px]">
                    <label className="text-black text-[13px] font-medium">
                      {formData?.isHideLeaseEndDate ? 'Rate Expires:' : 'Lease Ends:'}
                    </label>
                    <label className="text-[16px] font-bold">
                      {dayjs(lease && lease.leaseExpirationDate).format('MMMM D, YYYY')}
                    </label>
                  </div>
                </div>
                <p className="text-black text-left text-xs">
                  {getExpiryMessage(days, isHideLeaseEndDate)}.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportantDates;
