const uiReducer = (state, action) => {
  switch (action.type) {
    case 'getFormData':
      return {
        ...state,
        formData: action.payload,
        loading: false
      };
    case 'openMessageDialog':
      return {
        ...state,
        messageDialogOpen: true
      };
    case 'closeMessageDialog':
      return {
        ...state,
        messageDialogOpen: false,
        showSuccessMessage: false,
        showConfirmationPaymentMessage: false
      };
    case 'openPaymentDialog':
      return {
        ...state,
        messageDialogOpen: false,
        showSuccessMessage: false,
        showConfirmationPaymentMessage: true
      };
    case 'errorFetchData':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'handleSubmitForm':
      return {
        ...state,
        showSuccessMessage: true,
        submitForm: true
      };
    case 'submitingForm':
      return {
        ...state,
        submitingFormNTV: true
      };
    case 'submitedForm':
      return {
        ...state,
        submitingFormNTV: false
      };
    case 'setFormNoticeToVacate':
      return {
        ...state,
        form: action.payload
      };
    case 'openDialogError':
      return {
        ...state,
        showErrorMessage: true
      };
    case 'closeDialogError':
      return {
        ...state,
        showErrorMessage: false
      };
    case 'refresh':
      return {
        ...state,
        loading: true
      };
    case 'waitingResponse':
      return {
        ...state,
        loading: true
      };
    case 'getAuth':
      return {
        ...state,
        authLeases: action.payload.authLeases,
        hasMultipleLeases: action.payload.hasMultipleLeases,
        loading: false
      };
    case 'selectLease':
      return {
        ...state,
        hasSelectedLease: action.payload.hasSelectedLease
      };
    case 'updateValidationForm':
      return {
        ...state,
        isCurrentFormValid: action.payload.isCurrentFormValid,
        moveOutInformationRes: action.payload.moveOutInformationRes,
        reasonToMoveOutRes: action.payload.reasonToMoveOutRes,
        forwardingAddressRes: action.payload.forwardingAddressRes,
        termsRes: action.payload.termsRes
      };
    default:
      return state;
  }
};

export default uiReducer;
