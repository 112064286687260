import { Controller } from 'react-hook-form';
import DropDown from '../../../../../shared/DropDown';
import { UIContext } from '../../../../../context/UIContext';
import { useContext, useState, useEffect } from 'react';
import StepButtons from '../../../../../shared/StepButtons';

const ReasonToMoveOut = ({
  control,
  errors,
  disableForm,
  register,
  handleBack,
  handleStep,
  watch,
  setIsValidStep
}) => {
  const {
    formData,
    updateValidationForm,
    moveOutInformationRes,
    reasonToMoveOutRes,
    forwardingAddressRes
  } = useContext(UIContext);

  const { primaryResidenceTypes, moveOutReasons } = formData;
  const [reasonMoveOutForm, setReasonToMoveOutForm] = useState({});

  const updateForm = (data) => {
    const { reasonMoveOut, residenceTypeMoveIn, feedback } = data;

    setReasonToMoveOutForm({
      reasonMoveOut,
      residenceTypeMoveIn,
      feedback
    });

    updateValidationForm({
      moveOutInformationRes,
      forwardingAddressRes,
      reasonToMoveOutRes: { reasonMoveOut, residenceTypeMoveIn, feedback }
    });
  };

  const validateBtn = () => {
    const isValidStep =
      !!reasonMoveOutForm?.reasonMoveOut &&
      typeof reasonMoveOutForm?.reasonMoveOut === 'object' &&
      !!reasonMoveOutForm?.residenceTypeMoveIn &&
      typeof reasonMoveOutForm?.residenceTypeMoveIn === 'object';

    setIsValidStep(isValidStep);
    return isValidStep;
  };

  useEffect(() => {
    const subscription = watch((data) => {
      const updatedData = {
        ...moveOutInformationRes,
        ...forwardingAddressRes,
        ...reasonToMoveOutRes,
        ...Object.keys(data).reduce((acc, key) => {
          if (data[key] !== undefined || typeof data[key] === 'object') {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      };

      updateForm(updatedData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, reasonToMoveOutRes]);

  const prevState = () => {
    setReasonToMoveOutForm(reasonToMoveOutRes);
  };

  useEffect(() => {
    reasonToMoveOutRes && prevState();
  }, []);

  return (
    <div className="bg-white rounded w-11/12 lg:m-10 m-3 md:m-6 ">
      <p className="font-proxima  text-black text-[24px] font-semibold mb-1 md:text-[24px] md:font-medium">
        Reason to Move-out
      </p>
      <div className="md:flex   text-xs md:text-sm  mt-5">
        <div className="md:w-[30%] text-xs  md:text-sm">
          <label className="block  text-sm font-bold mb-2">
            <p className="font-semibold text-xs md:text-sm">
              <span className="text-[#F5222D]">*</span> Primary Reason For Moving Out
            </p>
          </label>
          <div>
            <Controller
              control={control}
              name="reasonMoveOut"
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  name={'reason'}
                  errors={errors}
                  errorName={'reasonMoveOut'}
                  data={moveOutReasons}
                  disableForm={disableForm}
                />
              )}
            />

            {errors?.reasonMoveOut && (
              <p className="text-[#F5222D] text-[10px] pt-2">
                Please select primary reason for moving out
              </p>
            )}
          </div>
        </div>
        <div className="w-0 md:w-[5%]"></div>
        <div className=" mt-5 md:mt-0 md:w-[30%]">
          <label className="block text-sm font-bold mb-2">
            <p className="font-semibold text-xs md:text-sm">
              <span className="text-[#F5222D] ">*</span> Residence Type You Will Be Moving Into
            </p>
          </label>

          <Controller
            control={control}
            name="residenceTypeMoveIn"
            defaultValue=""
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                onChange={(e) => {
                  onChange(e);
                }}
                name={'residence_type'}
                value={value}
                errors={errors}
                errorName={'residenceTypeMoveIn'}
                data={primaryResidenceTypes}
                disableForm={disableForm}
              />
            )}
          />
          {errors?.residenceTypeMoveIn && (
            <p className="text-[#F5222D] text-[10px] pt-2">Please select residence type</p>
          )}
        </div>
      </div>

      <div className="text-xs md:text-sm mt-5 md:w-[67%]">
        <label className="block text-sm font-bold mb-2">
          <p className="font-semibold text-xs md:text-sm">Feedback/Comments </p>
        </label>
        <textarea
          className=" appearance-none border border-[#BABCC0] rounded w-full py-2 px-3 md:w-[97%] h-[85px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067]  disabled:text-[#979AA0] disabled:italic disabled:border-slate-200 disabled:shadow-none"
          id="feedback"
          name="feedback"
          disabled={disableForm}
          value={reasonToMoveOutRes?.feedback || ''}
          type="text"
          {...register('feedback', {
            validate: {
              maxLength: (value) => value.length <= 700
            }
          })}
          placeholder="Please type here..."
          maxLength={700}
        />
      </div>
      <div className="xs:invisible md:visible">
        <StepButtons
          isValidForm={validateBtn()}
          handleBack={handleBack}
          handleStep={handleStep}
          confirmText="Next"
          cancelText="Previous"
        />
      </div>
    </div>
  );
};

export default ReasonToMoveOut;
