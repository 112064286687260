import star from '../../../../../assets/img/yellowstar_icon.png';
import LeaseTerminationSelection from './LeaseTerminationSelection';
import TableLeaseTermination from './TableLeaseTermination';
import info from '../../../../../assets/img/i-icon.png';
import ToolTipInfo from '../../../../../shared/ToolTipInfo';
import dayjs from 'dayjs';

const LeaseTermination = ({
  control,
  register,
  errors,
  leaseExpirationDate,
  disableForm,
  moveOutSelection,
  setValue,
  watch
}) => {
  const infoText = `Pay an up-front amount to
limit your rent responsibility `;

  return (
    <div className="font-proxima ">
      <div className=" border-b border-b-black w-full mb-6 mt-6"></div>

      <p className="font-proxima  text-sm font-semibold mb-1 md:text-[24px] md:font-medium pt-5">
        Lease Termination Options
      </p>
      <p className="text-xs md:text-sm font-normal">
        If you need to vacate before your lease ends, you have the following option(s):
      </p>
      <div className="flex pt-4 pb-5 items-center">
        <div>
          <img width={12} src={star} alt="star" />
        </div>
        <p className="text-xs font-bold pl-2 mt-[3px]">Easy Exit</p>
        <div className="pl-1">
          <ToolTipInfo icon={info} text={infoText} />
        </div>
      </div>
      <div className="pb-5">
        <p className="font-proxima text-xs md:text-sm">
          <span className="font-bold">Offer to Change Lease End Date</span> – With a minimum 30 day
          notice, we are accepting the following offers if you want to change your lease end date to
          an earlier date. To be effective, your offer selection must include payment of an Early
          Termination Charge by{' '}
          <span className="font-bold">{dayjs().add(1, 'day').format('M/DD/YYYY')}.</span>
        </p>
      </div>
      <div className="pb-5">
        <p className="font-proxima text-xs md:text-sm text-[#C64D5B] font-bold">
          The Early Termination Charge amounts below are valid today, {dayjs().format('M/DD/YYYY')}.
          The charge amounts may change or may no longer be an option if this form is submitted on
          another date.
        </p>
      </div>
      <TableLeaseTermination moveOutSelection={moveOutSelection} />
      <LeaseTerminationSelection
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        leaseExpirationDate={leaseExpirationDate}
        disableForm={disableForm}
        watch={watch}
      />
    </div>
  );
};

export default LeaseTermination;
