import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export default function DropDown(props) {
  const { data, value, onChange, name, errors, errorName, disableForm } = props;

  const styles = { divClass: 'relative mt-1 border w-full md:w-full rounded-md' };

  return (
    <Listbox value={value} onChange={onChange}>
      <div
        className={`${styles.divClass}
          ${errors !== undefined && errors[errorName] ? ' border-[#F5222D]' : 'border-[#BABCC0]'}
          ${disableForm && 'border-none'}
          `}>
        <Listbox.Button
          aria-label="dropDownBtn"
          className={
            disableForm
              ? 'relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left sm:text-sm pointer-events-none bg-[#BABCC067]'
              : 'relative w-full rounded-md bg-white py-[6px] pl-3 pr-10 text-left sm:text-sm cursor-pointer'
          }>
          <span className={disableForm ? 'block truncate text-[#979797]' : 'block truncate'}>
            {value[name] ? value[name] : '-Select-'}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        {!disableForm && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 md:z-0 mt-1 max-h-60 md:max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-tiny md:text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {data?.map((item) => (
                <Listbox.Option
                  aria-label="optionDropDown"
                  key={item.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-selectBackgroundcolor text-black' : 'text-gray-900'
                    }`
                  }
                  value={item}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {item[name]}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </Listbox>
  );
}
