/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { Menu } from '@headlessui/react';
import { UIContext } from '../context/UIContext';

const SignOutMenu = () => {
  const { formData, logout } = useContext(UIContext);
  const { resident } = formData;

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };
  return (
    <div>
      <Menu as="div" className="md:hidden">
        <Menu.Button aria-label="Menu">
          <div className="space-y-2 md:hidden">
            <div className="w-8 h-0.5 bg-gray-600"></div>
            <div className="w-8 h-0.5 bg-gray-600"></div>
            <div className="w-8 h-0.5 bg-gray-600"></div>
          </div>
        </Menu.Button>
        {resident && (
          <Menu.Items className="relative">
            <Menu.Items className="absolute w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      data-testid="back-to-avalon"
                      href={process.env.REACT_APP_AVALON_ACCESS_URL}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}>
                      &#8592;&nbsp;Back to Avalon Access
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      data-testid="logout-button"
                      onClick={logout}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}>
                      &#x5b;&#8592;&nbsp;Sign Out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu.Items>
        )}
      </Menu>
      <a href={process.env.REACT_APP_AVALON_ACCESS_URL}>
        <div className="xs:hidden  md:hidden lg:flex cursor-pointer font-proxima">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[12.5px] h-6 text-[#262626]">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>

          <div className="text-[#31343A] text-[14px] pl-2">Back to AvalonAccess</div>
        </div>
      </a>
    </div>
  );
};

export default SignOutMenu;
