import { createContext, useContext, useState } from 'react';

const LoaderContext = createContext(false);

// eslint-disable-next-line react/prop-types
export function LoaderProvider({ children }) {
  const [loading, setLoading] = useState(true);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>{children}</LoaderContext.Provider>
  );
}

export function useLoader() {
  const { loading, setLoading } = useContext(LoaderContext);

  return { loading, setLoading };
}
