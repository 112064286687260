/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';

import AVB_LOGO from '../../../assets/img/AVB_main_logo.jpg';
import { UIContext } from '../../../context/UIContext';

const MaintenanceScreen = () => {
  const { error } = useContext(UIContext);

  return (
    <div className="grid max-h-screen place-content-center body">
      <div className="flex w-full justify-center pt-[14%]  items-center content-center">
        <img src={AVB_LOGO} className="w-[100px] md:w-[150px]" alt="avb-logo" />
      </div>
      <div className="flex w-full justify-center pt-[5%] items-center content-center">
        <p className="text-[#31343A] text-[40px] md:text-[60px] font-proxima font-bold">
          We'll be back.
        </p>
      </div>
      <div className="xs:pt-[5%] md:pt-[2%]">
        <div className="flex items-center justify-center ">
          <div className="xs:w-[85%] md:w-[70%]">
            <p className=" xs:text-[16px] md:text-[24px] text-center text-[#31343A] font-proxima font-semibold">
              {error?.response?.data.errorMessage}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceScreen;
