/* eslint-disable no-undef */
import React from 'react';
import Header from '../../../shared/Header';

const UnauthorizedScreen = () => {
  let brand = 'avalon'; // TODO will pull from api

  return (
    <div
      data-theme="avalon"
      className={`${brand === 'ava' ? 'font-costa' : 'font-proxima'} xl:mb-8`}>
      <div className="max-w-screen-xl m-auto">
        <Header />
        <div className="grid h-screen place-items-center">
          <div className="rounded border border-black-400 p-4 bg-white">
            Please log into&nbsp;
            <a href={process.env.REACT_APP_AVALON_ACCESS_URL} className="inline">
              AvalonAccess
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedScreen;
